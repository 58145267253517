import React, { useEffect, useState } from "react";
import Header from "../../layout/Header/Header";
import { Button } from "react-bootstrap";
import _ from "lodash";
import html2canvas from "html2canvas";
import moment from "moment";
import jsPDF from "jspdf";
import MetaTags from 'react-meta-tags';

import AxiosInstance from "../../services/axios";
import useGeoLocation from "../../hooks/useGeoLocation";
import Filter from "../../components/FIlter/Filter";
import Searchbar from "../../components/Searchbar/Searchbar";
import Map from "../../components/Map/Map";
import ViewDetails from "../../components/ViewDetails/ViewDetails";

import "./homemapview.scss";

const HomeMapView = (props) => {
  const [toggleFilter, setToggleFilter] = useState(true);
  const [toggleView, setToggleView] = useState(true);
  const [mapView, setMapView] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checkToggle, setCheckToggle] = useState(false);
  const [service, setService] = useState(true);
  const [event, setEvent] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showList, setShowList] = useState([]);
  const [viewPage, setViewPage] = useState({});
  const [searchText, setsearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sliceData, setSliceData] = useState([]);
  console.log('sliceData->', sliceData);
  // const [, setBookmarkBtn] = useState(false);
  const location = useGeoLocation();

  let filterWrap = JSON.parse(localStorage.getItem('homeFilter'));

  let filterToggleWrap = JSON.parse(localStorage.getItem('toggleFilter'))||false;

  useEffect(() => {
    getFilterCategory();
  }, [toggleView]);

  useEffect(() => {
    setCheckToggle(filterToggleWrap)
  }, [])

  useEffect(() => {
    getMapView(categories, service, event, checkToggle);
  }, [categories, service, event, checkToggle]);


  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get("user/getCategory").then((response) => {
        let temp = response?.payload;

        let obj = {
          categoryName: "Uncategorised",
          color: "#000000",
          status: "Enable",
          _id: "6180f1fea7f078b966646017",
          ischecked: true
        };
        let newArr1 = [obj, ...temp];

        let tempArray = newArr1?.map((item, index) => {
          let filterData = filterWrap?.some((value) => value._id === item._id)
          if (filterData) {
            item['status'] = 'Disable';
          }
          return item;
        })
        setCategories(tempArray);
      });
    } catch (error) { }
  };

  const getMapView = async (category, serviceDetail, eventDetail, toggleDetail) => {

    setMapView([]);
    let temp = category
      ?.filter((item) => {
        if (item.status === "Enable") {
          return item;
        }
      })
      .map((item) => item._id);

    try {
      let params = {};
      if (!location.loaded === true) {
        params = {
          category: category ? temp : [],
          isOnlyBusiness: serviceDetail,
          isOnlyEvents: eventDetail,
          isOpen: toggleDetail,
          isUncategorised: eventDetail === true ? true : false,
        };
      } else {
        params = {
          longitude: parseInt(location?.coordinates?.lng),
          latitude: parseInt(location?.coordinates?.lat),
          // longitude: location?.coordinates?.lng,
          // latitude: location?.coordinates?.lat,
          // latitude: 51.509865,
          // longitude: -0.118092,
          // latitude: 56.462018,
          // longitude: -2.970721,
          category: category ? temp : [],
          isOnlyBusiness: serviceDetail,
          isOnlyEvents: eventDetail,
          isOpen: toggleDetail,
          isUncategorised: eventDetail === true ? true : false,
        };
        location?.coordinates?.lng && (params['longitude'] = location?.coordinates?.lng)
        location?.coordinates?.lat && (params['latitude'] = location?.coordinates?.lat)


      }


      await AxiosInstance.post("user/getServices", params).then((response) => {
        let businessList = response?.payload?.businessList;
        let eventList = response?.payload?.eventList;

        let merge = [...businessList, ...eventList];

        let bussinessCount = response?.payload?.businessCount;
        let eventCount = response?.payload?.eventCount;

        let mergeCount = bussinessCount + eventCount;


        setTotalCount(mergeCount);
        let tempArray = []
        const chunkSize = 75;
        for (let i = 0; i < merge.length; i += chunkSize) {
          const chunk = merge.slice(i, i + chunkSize);
          tempArray.push(chunk);;
          // do whatever
        }


        setMapView(merge);
        setFilteredDataSource(merge);
        setSliceData(tempArray);



        let isUpdated = false
        let bookmarkList = JSON.parse(localStorage.getItem('bookmark_data'));
        merge.map((item) => {
          bookmarkList?.map((bookmarkItem, index) => {
            if (bookmarkItem._id == item._id) {
              isUpdated = true
              bookmarkList[index] = item
            }
          })
        });
        if (isUpdated) {
          localStorage.setItem("bookmark_data", JSON.stringify(bookmarkList));
        }
      });
    } catch (error) { }
  };


  const searchFilterFunction = (text) => {
    if (!_.isEmpty(text)) {
      const newData = mapView.filter(function (item) {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text?.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData)
      setsearchText(text)
    } else {
      setsearchText(text)
      setFilteredDataSource(mapView)
    }

  };


  const downloadPDF = (data) => {
    // const input = document.getElementById('showData');
    //     html2canvas(input, { useCORS: true, pagesplit: true }).then(async (canvas) => {
    //       var imgData = canvas.toDataURL("image/png");
    //       console.log('imdData->', imgData);
    //       var imgWidth = 600;
    //       var pageHeight = 600;
    //       var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //       var heightLeft = imgHeight;
    //       var doc = new jsPDF("p", "pt", "a4");
    //       var position = 0;
    //       doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //       heightLeft -= pageHeight;

    //       while (heightLeft >= 0) {
    //         position = heightLeft - imgHeight;
    //         doc.addPage();
    //         doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    //         heightLeft -= pageHeight;
    //       }
    //       // if (i !== sliceData.length - 1) {
    //       //   doThing(i + 1);
    //       // }
    //     doc.save("services.pdf");
    //     });
    let start = 0;
    function doThing(count) {
      for (let i = count; i < sliceData.length; i++) {
        console.log('check loop');
        const input = document.getElementById(`services${i}`);
        html2canvas(input, { useCORS: true, pagesplit: true }).then(async (canvas) => {
          // canvas.getContext('2d', { willReadFrequently: true });
          var imgData = canvas.toDataURL("image/png");
          console.log('imdData->', imgData);
          var imgWidth = 600;
          var pageHeight = 600;
          var imgHeight = (canvas.height * imgWidth) / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF("p", "pt", "a4");
          var position = 0;
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          if (i !== sliceData.length - 1) {
            doThing(i + 1);
          }
        await doc.save("services.pdf");
        });
        break;
      }
    }
    doThing(start);
  };

  let localstorageBooks =
    JSON.parse(localStorage.getItem("bookmark_data")) || [];
  return (
    <React.Fragment>
      <MetaTags>
        <title>Dundee Recovery Road Map - Supporting Individuals & Families</title>
        <meta name="description" content="Find services in Tayside for mental health support, food provision, harm reduction, addiction & recovery support, rehabilitation, and more." />
        <meta property="og:title" content="Dundee Recovery Road Map - Supporting Individuals & Families" />
        <meta property="og:description" content="Find services in Tayside for mental health support, food provision, harm reduction, addiction & recovery support, rehabilitation, and more." />
        <meta property="og:image" content="https://res.cloudinary.com/zudu/image/upload/v1667544192/Parish_Nursing/Parish-Nursing-Web/Project-images/socialmediapreview.png" />
      </MetaTags>
      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          count={totalCount}
          onCheckedServices={(value) => {

            setService(value);
            // getMapView(categories, value, event, checkToggle);
          }}
          onCheckedEvent={(value) => {
            setEvent(value);
            // getMapView(categories, service, value, checkToggle);
          }}
          onCheckedToggle={(value) => {
            setCheckToggle(value);
            // getMapView(categories, service, event, value);
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value]);
            // getMapView(value, service, event, checkToggle);
          }}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />

        {toggleView === false ?
          <Searchbar
            value={searchText}
            onSearch={(e) => {
              searchFilterFunction(e.target.value);
              setsearchText(e.target.value);
            }}
            onClear={() => {
              setsearchText('')
              searchFilterFunction('')

            }}
          />
          : null}
        <div className="viewWrap">
          <Button
            className={toggleView ? "mapview-btn active" : "mapview-btn "}
            onClick={() => setToggleView(true)}
          >
            Map View
          </Button>
          <Button
            className={!toggleView ? "listview-btn active" : "listview-btn "}
            onClick={() => { setToggleView(false); setsearchText('') }}
          >
            List View
          </Button>
        </div>
        <div className={!toggleView ? "mapWrap hide" : "mapWrap"}>
          <Map mapData={mapView} />
        </div>

        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
        <div className={toggleView ? "listVieWrap hide" : "listVieWrap"}>
          {filteredDataSource?.map((item, index) => {
            item["isbookmarked"] = localstorageBooks?.some(
              (val) => val?._id === item?._id
            );

            let displayTime;
            let startDate;
            let endDate;
            let st = "Open";
            if (item?.type === "event") {
              startDate = moment(`${moment(item?.eventStartDate)
                .utc()
                .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
              if (item?.eventEndDate) {
                endDate = moment(`${moment(item?.eventEndDate)
                  .utc()
                  .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
              } else {
                endDate = "--:--";
              }
            } else {
              let currentDay = moment().format("ddd");
              let data = item?.availableDayTime?.filter((businessItem) => {
                if (businessItem?.day === currentDay) {
                  return businessItem;
                } else if (businessItem?.day !== currentDay) {
                }
              });
              // st = timingStatus(item?.availableDayTime, item?.isActive);
              displayTime = data[0]?.isDayActive
                ? data[0].is24Hours
                  ? "24 Hours available"
                  : `${data[0].startTime} to ${data[0].endTime}`
                : "Closed";
            }

            return (
              <>
                <div
                  key={index.toString()}
                  //  onClick={() => setShowModal(true)}
                  onClick={() => {
                    setShowModal(true);
                    setViewPage(item);

                  }}
                  style={{ color: item.color }}
                  className={"eventBox"}
                >
                  {item?.type === "event" ? (
                    <span className="icon icon-activity-pin"></span>
                  ) : (
                    <span className="icon icon-service-pin"></span>
                  )}
                  <div className="cardContent">
                    <div className="eventTop ">
                      <h2>
                        {item?.name}{" "}
                        {item.distance &&
                          <span className="distance">
                            {parseFloat(item?.distance).toFixed(2)} miles
                          </span>
                        }
                      </h2>
                      <span className="category">{item?.categoryName}</span>
                    </div>
                    {item?.isActive !== "Open" &&
                      item?.isActive !== "open" ? (
                      <div className="timing">
                        <span>Timing - </span> {item?.isActive === 'Close' ? 'Closed' : item?.isActive}
                      </div>
                    ) : (
                      <div className="timing">
                        {" "}
                        <span>{item.isActive}</span>
                      </div>
                    )}
                    <div className="date">
                      {item?.type === "event"
                        ? `${startDate} - ${endDate}`
                        : displayTime}
                    </div>
                    <div className="btn-wrap">
                      <a
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="btn btn-primary"
                        href={`https://www.google.com/maps/?q=${item.location.coordinates[1]},${item.location.coordinates[0]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="icon icon-coolicon"></span>Directions
                      </a>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          // setBookmarkBtn(true);
                          setToggleView(false);
                          const getbookMarkBookArr = JSON.parse(localStorage.getItem("bookmark_data"));
                          const isBookMarkAdded = getbookMarkBookArr?.some((itemData) => item?._id === itemData?._id);
                          let localList = [...filteredDataSource];

                          if (isBookMarkAdded) {
                            if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
                              const newData = getbookMarkBookArr.filter((val) => item._id !== val._id);
                              localList[index]["isbookmarked"] = false;
                              item["isbookmarked"] = false;
                              localStorage.setItem("bookmark_data", JSON.stringify(newData));
                            }
                          } else {
                            let bookMarkArr = JSON.parse(localStorage.getItem("bookmark_data"));
                            if (bookMarkArr && bookMarkArr.length > 0) {
                              bookMarkArr.push(item);
                              localStorage.setItem("bookmark_data", JSON.stringify(bookMarkArr));
                            } else {
                              localList[index]["isbookmarked"] = true;
                              item["isbookmarked"] = true;
                              localStorage.setItem("bookmark_data", JSON.stringify([item]));
                            }
                          }
                          setFilteredDataSource([...localList]);
                        }}
                        className={
                          item.isbookmarked === true
                            ? "isbookmarked"
                            : ""
                        }
                      >
                        <span className="icon icon-bookmark-outline"></span>
                        Bookmark
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <Button
            className="download"
            onClick={() => {
              downloadPDF();
            }}
          >
            <span className="icon icon-download"></span>
          </Button>
        </div>
        <ViewDetails />
        {/* <div id="showData" className="showData" >
            <table className="titleTable">
              <thead>
                <tr>
                  <td colSpan="2" className="service">
                    <span>Service Centers</span>
                  </td>
                </tr>
              </thead>
            </table>
            {filteredDataSource?.map((item, index) => {

              let displayTime;
              let startDate;
              let endDate;
              let st = "Open";
              if (item?.type === "event") {
                startDate = moment(`${moment(item?.eventStartDate)
                  .utc()
                  .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                if (item?.eventEndDate) {
                  endDate = moment(`${moment(item?.eventEndDate)
                    .utc()
                    .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                } else {
                  endDate = "--:--";
                }
              } else {
                let currentDay = moment().format("ddd");
                let data = item?.availableDayTime?.filter((businessItem) => {
                  if (businessItem?.day === currentDay) {
                    return businessItem;
                  } else if (businessItem?.day !== currentDay) {
                  }
                });
                // st = timingStatus(item?.availableDayTime, item?.isActive);
                displayTime = data[0]?.isDayActive
                  ? data[0].is24Hours
                    ? "24 Hours available"
                    : `${data[0].startTime} to ${data[0].endTime}`
                  : "Closed";
              }


              return (
                <>
                  <table className="showTable" key={index.toString()}  >
                    <tbody>
                      <tr>
                        <td>
                          <table className="secondTable">
                            <tr>
                              <td className="categoryList">
                                <div className="category">{item?.categoryName}</div>
                                <div className="name">{item?.name}</div>
                                <div className="description">{item?.description}</div>
                              </td>
                              <td className={'categoryList' + 'categoryList1'}>
                                <div className="serviceTime">
                                  {item?.type === "event" ? (
                                    <span>Event Timing</span>
                                  ) : (
                                    <span>Service Timing</span>
                                  )}
                                </div>
                                <div className="serviceData">
                                  {item?.type === "service" ? (
                                    item?.availableDayTime?.map((item, index) => {

                                      let currentDay = moment().format("ddd");
                                      if (item.day === currentDay) {
                                        const timing = item.isDayActive
                                          ? item.is24Hours
                                            ? "24 Hours available"
                                            : item.startTime + "-" + item.endTime
                                          : "Closed";
                                        return (
                                          <>
                                            <div key={index.toString()} className="serviceTiming">
                                              <span className="serviceDay">
                                                {item.day}
                                              </span>
                                              {timing}
                                              <span className="isActive serviceDay">
                                                {showList?.isActive === 'Close' ? 'Closed' : item?.isActive}
                                              </span>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        const timing = item.isDayActive
                                          ? item.is24Hours
                                            ? "24 Hours available"
                                            : item.startTime + "-" + item.endTime
                                          : "Closed";
                                        return (
                                          <>
                                            <div className="serviceTiming">
                                              <span className="serviceDay">
                                                {item.day}
                                              </span>
                                              {timing}
                                            </div>
                                          </>
                                        );
                                      }
                                    })
                                  ) : (
                                    item?.type === "event"
                                      ? `${startDate} - ${endDate}`
                                      : null
                                  )}
                                </div>
                              </td>
                              <td className={'categoryList' + 'categoryList2'}>
                                <div className="eventTime">Events</div>
                                <div className="eventData">
                                  {showList?.type === "service" &&
                                    showList?.relatedEvents?.map(
                                      (item, index) => {
                                        return (
                                          <div key={index.toString()}>
                                            <span>{item?.name}</span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                            <tr>
                              <td className="categoryList3">
                                <div className="location">
                                  <span>Location:</span>
                                  {item?.Address
                                    ? item?.Address
                                    : "Not available"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                            <tr>
                              {item?.type === "service" ? (
                                <>
                                  <td className="categoryList3">
                                    <div className="location">
                                      <span>Website:</span>
                                      {item?.websiteLink
                                        ? item?.websiteLink
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Twitter:</span>
                                      {item?.twitterLink
                                        ? item?.twitterLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                  <td className={"categoryList3" + "locationTime"}>
                                    <div className="location">
                                      <span>Phone number:</span>
                                      {item?.phoneNumber
                                        ? item?.phoneNumber
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Facebook:</span>
                                      {item?.facebookLink
                                        ? item?.facebookLink
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Linkedin:</span>
                                      {item?.linkedLink
                                        ? item?.linkedLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                  <td className="categoryList3">
                                    <div className="location">
                                      <span>Email address:</span>
                                      {item?.email
                                        ? item?.email
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Instagram:</span>
                                      {item?.instagramLink
                                        ? item?.instagramLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                </>
                              ) : null}
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <tr>
                      <td style={{ height: 16 }} height={"16"}></td>
                    </tr>
                  </table>
                </>
              );
            })}
          </div> */}
        {
          sliceData.map((data, index) => {
              return <div id={`services${index}`} className="showData">
            {/* return <div id="showData" className="showData"> */}
              <table className="titleTable">
                <thead>
                  <tr>
                    <td colSpan="2" className="service">
                      <span>Service Centers</span>
                    </td>
                  </tr>
                </thead>
              </table>

              <table className="showTable"  >
                <tbody>
                  {data?.map((item, j) => {
                    let displayTime;
                    let startDate;
                    let endDate;
                    let st = "Open";
                    if (item?.type === "event") {
                      startDate = moment(`${moment(item?.eventStartDate)
                        .utc()
                        .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                      if (item?.eventEndDate) {
                        endDate = moment(`${moment(item?.eventEndDate)
                          .utc()
                          .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                      } else {
                        endDate = "--:--";
                      }
                    } else {
                      let currentDay = moment().format("ddd");
                      let data = item?.availableDayTime?.filter((businessItem) => {
                        if (businessItem?.day === currentDay) {
                          return businessItem;
                        } else if (businessItem?.day !== currentDay) {
                        }
                      });
                      // st = timingStatus(item?.availableDayTime, item?.isActive);
                      displayTime = data[0]?.isDayActive
                        ? data[0].is24Hours
                          ? "24 Hours available"
                          : `${data[0].startTime} to ${data[0].endTime}`
                        : "Closed";
                    }


                    return (
                      <tr key={j}>
                        <td>
                          <table className="secondTable">
                            <tr>
                              <td className="categoryList">
                                <div className="category">{item?.categoryName}</div>
                                <div className="name">{item?.name}</div>
                                <div className="description">{item?.description}</div>
                              </td>
                              <td className="categoryList categoryList1">
                                <div className="serviceTime">
                                  {item?.type === "event" ?
                                    <span>Event Timing</span>
                                    :
                                    <span>Service Timing</span>
                                  }
                                </div>
                                <div className="serviceData">
                                  {item?.type === "service" ? (
                                    item?.availableDayTime?.map((item, index) => {

                                      let currentDay = moment().format("ddd");
                                      if (item.day === currentDay) {
                                        const timing = item.isDayActive
                                          ? item.is24Hours
                                            ? "24 Hours available"
                                            : item.startTime + "-" + item.endTime
                                          : "Closed";
                                        return (
                                          <>
                                            <div className="serviceTiming">
                                              <span className="serviceDay">
                                                {item.day}
                                              </span>
                                              {timing}
                                              <span className="isActive serviceDay">
                                                {showList?.isActive === 'Close' ? 'Closed' : item?.isActive}
                                              </span>
                                            </div>
                                          </>
                                        );
                                      } else {
                                        const timing = item.isDayActive
                                          ? item.is24Hours
                                            ? "24 Hours available"
                                            : item.startTime + "-" + item.endTime
                                          : "Closed";
                                        return (
                                          <>
                                            <div className="serviceTiming">
                                              <span className="serviceDay">
                                                {item.day}
                                              </span>
                                              {timing}
                                            </div>
                                          </>
                                        );
                                      }
                                    })
                                  ) : (
                                    item?.type === "event"
                                      ? `${startDate} - ${endDate}`
                                      : null
                                  )}
                                </div>
                              </td>
                              <td className="categoryList categoryList2">
                                <div className="eventTime">Events</div>
                                <div className="eventData">
                                  {showList?.type === "service" &&
                                    showList?.relatedEvents?.map(
                                      (item, index) => {
                                        return (
                                          <div key={index.toString()}>
                                            <span>{item?.name}</span>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                            <tr>
                              <td className="categoryList3">
                                <div className="location">
                                  <span>Location:</span>
                                  {item?.Address
                                    ? item?.Address
                                    : "Not available"}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                            <tr>
                              {item?.type === "service" ? (
                                <>
                                  <td className="categoryList3">
                                    <div className="location">
                                      <span>Website:</span>
                                      {item?.websiteLink
                                        ? item?.websiteLink
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Twitter:</span>
                                      {item?.twitterLink
                                        ? item?.twitterLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                  <td className="categoryList3 locationTime">
                                    <div className="location">
                                      <span>Phone number:</span>
                                      {item?.phoneNumber
                                        ? item?.phoneNumber
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Facebook:</span>
                                      {item?.facebookLink
                                        ? item?.facebookLink
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Linkedin:</span>
                                      {item?.linkedLink
                                        ? item?.linkedLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                  <td className="categoryList3">
                                    <div className="location">
                                      <span>Email address:</span>
                                      {item?.email
                                        ? item?.email
                                        : "Not available"}
                                    </div>
                                    <div className="location">
                                      <span>Instagram:</span>
                                      {item?.instagramLink
                                        ? item?.instagramLink
                                        : "Not available"}
                                    </div>
                                  </td>
                                </>
                              ) : null}
                            </tr>
                            <tr>
                              <td style={{ height: 16 }} height={"16"}></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    );
                  })}

                </tbody>
              </table>
              <table>
                <tr>
                  <td style={{ height: 16 }} height={"16"}></td>
                </tr>
              </table>
            </div>
          })
        }
      </div>
    </React.Fragment >
  );
};

export default HomeMapView;
