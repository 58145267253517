import { Modal } from 'react-bootstrap';
import React from "react";
import './privacymodalbox.scss'
import parse from 'html-react-parser';


const PrivacyModalBox = (props) => {


  return (
    <>

      <Modal centered className='privacyModal' show={props.showModal} onHide={() => {
        props.modalClose()
      }}>
        <Modal.Header closeButton>
          <Modal.Title className='privacy-head'>Privacy Policy<span>Last Updated January 10,2021</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>{parse(props?.policyData)}</Modal.Body>
      </Modal>
    </>
  );
};
export default PrivacyModalBox;