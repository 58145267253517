import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { MarkerF, Marker } from "@react-google-maps/api";
import GoogleMapStyle from './../Map/GoogleMapStyle.json'
import "./contactus.scss";

const containerStyle = {
  width: "100%",
  height: "536px",
};

const center = {
  lat: 56.462018,
  lng: -2.970721000000026,
};

const ContactUs = (props) => {
  let { email } = props.contactData;
  let { location } = props.contactData;
  let { phoneNumber } = props.contactData;
  const history = useLocation();

  return (
    <>

      <Modal centered
        className="contactModal map_padding"
        show={props.showModal}
        onHide={() => {
          props.modalClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="support-head">Contact us</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {
            history.pathname === '/' ||  history.pathname === '/event' || history.pathname === '/bookmark' ?
              <GoogleMap
                mapContainerStyle={containerStyle}
                options={{ styles: GoogleMapStyle, disableDefaultUI: true }}
                center={center}
                zoom={15}
              >
                <MarkerF
                  key={"1"}
                  position={{ lat: location?.coordinates[1], lng: location?.coordinates[0] }}
                  icon={{
                    path: "M19.8608 0.595947C19.5738 0.595947 19.2869 0.595947 19 0.595947C8.52629 0.595947 0.0612564 8.97997 0.0612564 19.3534C0.0612564 28.448 6.66113 36.1215 15.4131 37.8267L17.8522 42.6581C18.2827 43.6528 19.7174 43.6528 20.1478 42.6581L22.5869 37.8267C31.3389 36.1214 37.9388 28.59 37.9388 19.3534C37.9388 9.26416 29.9041 1.02215 19.8609 0.595947H19.8608ZM22.013 31.2901C21.2957 31.4322 20.4347 31.5743 19.5739 31.5743C19.4305 31.5743 19.2869 31.5743 19.1435 31.5743C12.2566 31.5743 6.66113 26.0323 6.66113 19.2114C6.66113 12.3906 12.2567 6.84855 19.1435 6.84855C19.2869 6.84855 19.4305 6.84855 19.5739 6.84855H19.7174C26.3173 7.13278 31.4824 12.5327 31.4824 19.2114C31.4824 25.1797 27.3216 30.0112 22.013 31.2901H22.013ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901Z",
                    fillColor: '#FF9800',
                    strokeColor: '#FF9800',
                    fillOpacity: 1,
                    anchor: window.google && new window.google.maps.Point(17, 46),
                    scaledSize: window.google && new window.google.maps.Size(37, 37)
                  }}
                />
              </GoogleMap>
              :
              <LoadScript googleMapsApiKey="AIzaSyDYiRK54loDXDLRIxB9nl5R8fvSozD2PPM">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  options={{ styles: GoogleMapStyle, disableDefaultUI: true }}
                  center={center}
                  zoom={15}
                >
                  <MarkerF
                    position={{ lat: location?.coordinates[1], lng: location?.coordinates[0] }}
                    icon={{
                      path: "M19.8608 0.595947C19.5738 0.595947 19.2869 0.595947 19 0.595947C8.52629 0.595947 0.0612564 8.97997 0.0612564 19.3534C0.0612564 28.448 6.66113 36.1215 15.4131 37.8267L17.8522 42.6581C18.2827 43.6528 19.7174 43.6528 20.1478 42.6581L22.5869 37.8267C31.3389 36.1214 37.9388 28.59 37.9388 19.3534C37.9388 9.26416 29.9041 1.02215 19.8609 0.595947H19.8608ZM22.013 31.2901C21.2957 31.4322 20.4347 31.5743 19.5739 31.5743C19.4305 31.5743 19.2869 31.5743 19.1435 31.5743C12.2566 31.5743 6.66113 26.0323 6.66113 19.2114C6.66113 12.3906 12.2567 6.84855 19.1435 6.84855C19.2869 6.84855 19.4305 6.84855 19.5739 6.84855H19.7174C26.3173 7.13278 31.4824 12.5327 31.4824 19.2114C31.4824 25.1797 27.3216 30.0112 22.013 31.2901H22.013ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901ZM19.4305 6.99056C19.287 6.99056 19.287 6.99056 19.4305 6.99056V6.99056ZM21.726 31.2901C21.0087 31.4322 20.1478 31.5743 19.2869 31.5743C20.1478 31.5743 21.0087 31.2901 21.726 31.0059V31.2901V31.2901Z",
                      fillColor: '#FF9800',
                      strokeColor: '#FF9800',
                      fillOpacity: 1,
                    }}
                  />
                </GoogleMap>
              </LoadScript>
          }
          <div className="boxWrap d-flex flex-wrap align-items-center justify-content-between">
            <a onClick={(e) => {
              e.stopPropagation();
            }}
              href={`https://www.google.com/maps/?q=${location?.coordinates[1]},${location?.coordinates[0]}`}
              target="_blank"
              rel="noreferrer" className="contactBox d-flex align-items-center"><div className="leftside">{props.contactData.address}<span>Dundee Recovery Road Map</span></div><span className="icon icon-marker"></span></a>
            <a href="tel:`${phoneNumber}`" className="contactBox d-flex align-items-center"><div className="leftside">{props.contactData.phoneNumber}<span>Phone Number</span></div><span className="icon icon-call"></span></a>
            <a href="mailto:`${props.contactData.email}`" target="_blank" className=" contactBox d-flex align-items-center"><div className="leftside">{email}<span>Email Address</span></div><span className="icon icon-mail"></span></a>
          </div>
          <a onClick={(e) => {
            e.stopPropagation();
          }}
            href={`https://www.google.com/maps/?q=${location?.coordinates[1]},${location?.coordinates[0]}`}
            target="_blank"
            rel="noreferrer" className="directionIcon"><span className="icon icon-coolicon"></span></a>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ContactUs;
