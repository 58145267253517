import React, { useState, useEffect } from 'react';
import './CategoryCheckbox.scss';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
    const { labelTitle, id, onCheckedChange, register, name, disabled, uniqueId, color } = props;
    const [checked, setChecked] = useState(!props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const onChecked = (e) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };

    return (
        <div className="checkboxMain1" key={`check_${uniqueId}`} >
            <input type="checkbox" ref={register} name={name} value={labelTitle} id={id} disabled={disabled} className='checkbox' checked={checked} onChange={onChecked} onClick={props.onClick} />
            <label className='checkboxLabel' id="checkLabel" htmlFor={id}>{labelTitle}</label>
            <span className={checked ? 'checked' : 'unchecked'} style={{ backgroundColor: checked ? '#fff' : color, border: `3px solid ${color}` }}><i style={{ borderColor: color }} ></i></span>
        </div>
    );
};

CheckBox.defaultProps = {
    id: '',
    checked: false,
    labelTitle: 'Title',

};

CheckBox.propTypes = {
    disabled: PropTypes.bool,
}

export default CheckBox;
