import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import moment from 'moment';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import MetaTags from 'react-meta-tags';

import AxiosInstance from "../../services/axios";
import useGeoLocation from "../../hooks/useGeoLocation";
import Header from "../../layout/Header/Header";
import Filter from "../../components/FIlter/Filter";
import ViewDetails from "../../components/ViewDetails/ViewDetails";

import "../HomeMapView/homemapview.scss";


const Event = () => {
  const [toggleFilter, setToggleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [checkToggle, setCheckToggle] = useState(false);
  const [service, setService] = useState(true);
  const [event, setEvent] = useState(true);
  const [showList, setShowList] = useState([]);
  const [viewPage, setViewPage] = useState({})
  const [locationDetails, setLocationDetails] = useState(null)
  const [mapView, setMapView] = useState([]);
  const [eventShowCount, setEventShowCount] = useState(0);
  const [bookmarkBtn, setBookmarkBtn] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  let filterWrap = JSON.parse(localStorage.getItem('homeFilter'));
  let filterToggleWrap = JSON.parse(localStorage.getItem('toggleFilter'));

  useEffect(() => {
    getFilterCategory();
  }, [])

  useEffect(() => {
    setCheckToggle(filterToggleWrap)
  }, [])

  useEffect(() => {
    getMapView(categories, service, event, checkToggle);
  }, [categories])

  const location = useGeoLocation();

  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get('user/getCategory').then
        ((response) => {
          let temp = response?.payload;
          let obj = {
            categoryName: "Uncategorised",
            color: "#000000",
            status: "Enable",
            _id: "6180f1fea7f078b966646017"
          }
          let newArr1 = [obj, ...temp]

          let tempArray = newArr1.map((item, index) => {
            let filterData = filterWrap?.some((value) => value._id === item._id)
            if (filterData) {
              item['status'] = 'Disable';
            }
            return item;
          })

          setCategories(tempArray);
        })
    } catch (error) {
    }
  };
  const getMapView = async (category, serviceDetail, eventDetail, toggleDetail) => {

    let temp = category?.filter((item, index) => {
      if (item.status === "Enable") {
        return item
      }
    }).map(item => item._id);
    try {
      let params = {}
      if (location === null) {
        params = {
          category: category ? temp : [],
          isOnlyBusiness: serviceDetail,
          isOnlyEvents: eventDetail,
          isOpen: toggleDetail,
          isUncategorised: eventDetail === true ? true : false,
        }
      } else {
        params = {
          // longitude: parseInt(location?.coordinates?.lat),
          // latitude: parseInt(location?.coordinates?.lng),
          // latitude: 56.462018,
          // longitude: -2.970721,
          category: category ? temp : [],
          isOnlyBusiness: serviceDetail,
          isOnlyEvents: eventDetail,
          isOpen: toggleDetail,
          isUncategorised: eventDetail === true ? true : false,
        }
        location?.coordinates?.lng && (params['longitude'] = location?.coordinates?.lng)
        location?.coordinates?.lat && (params['latitude'] = location?.coordinates?.lat)
      }

      await AxiosInstance.post('user/getServices', params).then
        ((response) => {

          let eventList = response?.payload?.eventList;
          let eventCounts = response?.payload?.eventCount;

          setMapView(eventList);
          setEventShowCount(eventCounts);
          setFilteredDataSource(eventList);


        });
    } catch (error) {

    }
  }
  const timingStatus = (dayTimeArray = [], status) => {
    const today = moment().utc();
    const day = today.format('ddd')
    let currentDay = {}
    let endTime;
    let startTime;
    let closeText = 'Closed';
    currentDay = dayTimeArray.find((d) => d.day === day);
    if (Object.keys(currentDay).length > 0) {
      startTime = moment(`${today.format('DD/MM/YYYY')} ${currentDay.startTime}`, 'DD/MM/YYYY HH:mm');
      endTime = moment(`${today.format('DD/MM/YYYY')} ${currentDay.endTime}`, 'DD/MM/YYYY HH:mm');
      if (today.isBetween(startTime, endTime) && status !== 'Close') {
        closeText = 'Open';
      }
    }
    return closeText;
  }
  const downloadPDF = (data) => {

    const input = document.getElementById('showData');
    html2canvas(input, { useCORS: true, pagesplit: true }).then((canvas) => {
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 600;
      var pageHeight = 600;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'pt', 'a4');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('file.pdf');
    });
  }

  let localstorageBooks = JSON.parse(localStorage.getItem('bookmark_data')) || [];
  return (
    <div>
      <MetaTags>
        <title>Events In Tayside & Angus | Dundee Recovery Road Map</title>
        <meta name="description" content="Find out more about the latest community events in and around Dundee & Tayside to help support your recovery journey." />
        <meta property="og:title" content="Events In Tayside & Angus | Dundee Recovery Road Map" />
        <meta property="og:description" content="Find out more about the latest community events in and around Dundee & Tayside to help support your recovery journey." />
        <meta property="og:image" content="https://res.cloudinary.com/zudu/image/upload/v1667544192/Parish_Nursing/Parish-Nursing-Web/Project-images/socialmediapreview.png" />
      </MetaTags>
      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          countedEvents={eventShowCount}
          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          isFromEvent={true}
          onCheckedToggle={(value) => {
            setCheckToggle(value)
            getMapView(categories, service, event, value)
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value])
            getMapView(value, service, event, checkToggle)
          }}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />
        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)} />
        <div className="eventViewrap listVieWrap">
          {filteredDataSource?.length === 0 ?
              < div className="no_data">
              <i className='icon-info_circle_outline'></i>
              <p>Oops! No events found.</p>
            </div>
               :
            filteredDataSource?.map((item, index) => {

            item['isbookmarked'] = localstorageBooks?.some((val) => val?._id === item?._id)

            let startDate;
            let endDate;
            let st = 'Open';
            if (item.type === 'event') {
              startDate = moment(`${moment(item?.eventStartDate).utc().format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
              if (item?.eventEndDate) {
                endDate = moment(`${moment(item?.eventEndDate).utc().format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
              } else {
                endDate = "--:--"
              }
            } else {
              let currentDay = moment().format("ddd");
              let data = item?.availableDayTime?.filter((businessItem) => {
                if (businessItem?.day === currentDay) {
                  return businessItem;
                }
                else if (businessItem?.day !== currentDay) {

                }
              })
              st = timingStatus(item?.availableDayTime, item?.isActive);
            }

            return (
              <div key={item.categoryId} onClick={() => {
                setShowModal(true)
                setViewPage(item)
              }}
                style={{ color: item.color }} className={"eventBox "}>
                {item?.type === "event" ? (
                  <span className="icon icon-activity-pin"></span>
                ) : (
                  <span className="icon icon-service-pin"></span>
                )}
                <div className="cardContent">
                  <div className="eventTop ">
                    <h2>
                      {item?.name}{" "}
                      {item?.distance && <span className="distance">{parseFloat(item?.distance).toFixed(2)} miles</span>}
                    </h2>
                    <span className="category">{item?.categoryName}</span>
                  </div>
                  {item?.isActive !== "Open" && item?.isActive !== "open" ? (
                    <div className="timing">
                      <span>Timing - </span> {item?.isActive}
                    </div>
                  ) : (
                    <div className="timing">
                      {" "}
                      <span>{item?.isActive}</span>
                    </div>
                  )}
                  <div className="date">{`${startDate} - ${endDate}`}</div>
                  <div className="btn-wrap">
                    <a onClick={(e) => { e.stopPropagation(); }} className="btn btn-primary" href={`https://www.google.com/maps/?q=${item?.location?.coordinates[1]},${item?.location?.coordinates[0]}`}
                      target="_blank" rel="noreferrer">
                      <span className="icon icon-coolicon"></span>Directions
                    </a>
                    <Button onClick={(e) => {
                      e.stopPropagation();
                      setBookmarkBtn(true)
                      const getbookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                      const isBookMarkAdded = getbookMarkBookArr?.some((itemData) => item._id === itemData._id)
                      let localList = [...filteredDataSource]

                      if (isBookMarkAdded) {
                        if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
                          const newData = getbookMarkBookArr.filter((val) => item._id !== val._id)
                          localList[index]['isbookmarked'] = false
                          item['isbookmarked'] = false
                          localStorage.setItem('bookmark_data', JSON.stringify(newData));
                        }

                      }
                      else {
                        let bookMarkArr = JSON.parse(localStorage.getItem('bookmark_data'));
                        if (bookMarkArr && bookMarkArr.length > 0) {
                          bookMarkArr.push(item)
                          localStorage.setItem('bookmark_data', JSON.stringify(bookMarkArr));
                        } else {
                          localList[index]['isbookmarked'] = true
                          item['isbookmarked'] = true
                          localStorage.setItem('bookmark_data', JSON.stringify([item]));
                        }
                      }
                      setFilteredDataSource([...localList])
                    }}
                      className={item.isbookmarked === true ? 'isbookmarked' : ''}>
                      <span className="icon icon-bookmark-outline"></span>
                      Bookmark
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
          <Button className="download" onClick={() => {
            downloadPDF()
          }}>
            <span className="icon icon-download"></span>
          </Button>
        </div>
      </div>
      <div className="showData" id="showData">
        {mapView?.map((item, index) => {

          let startDate;
          let endDate;
          let st = "Open";
          if (item?.type === "event") {
            startDate = moment(`${moment(item?.eventStartDate)
              .utc()
              .format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
            if (item?.eventEndDate) {
              endDate = moment(`${moment(item?.eventEndDate)
                .utc()
                .format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
            } else {
              endDate = "--:--";
            }
          } else {
            let currentDay = moment().format("ddd");
            let data = item?.availableDayTime?.filter((businessItem) => {
              if (businessItem?.day === currentDay) {
                return businessItem;
              } else if (businessItem?.day !== currentDay) {
              }
            });
          }

          return (
            <table className="showTable" key={index.toString()}>
              <tbody>
                <tr>
                  <td>
                    <table className="titleTable">
                      <tr>
                        <td colSpan="2" className="service">
                          <span>Events</span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                    </table>
                    <table className="secondTable">
                      <tr>
                        <td className="categoryList">
                          <div className="category">{item?.categoryName}</div>
                          <div className="name">{item?.name}</div>
                          <div className="description">{item?.description}</div>
                        </td>
                        <td className=" categoryList categoryList1">
                          <div className="serviceTime">{item?.type === "event" ? <span>Event Timing</span> : <span>Service Timing</span>}</div>
                          <div className="serviceData">
                            {item?.type === 'event' ?
                              `${startDate} - ${endDate}` :
                              null
                            }
                          </div>
                        </td>
                        <td className=" categoryList categoryList2">
                          <div className="eventTime"></div>
                          <div className="eventData"> {showList?.type === 'service' &&
                            showList?.relatedEvents?.map((item, index) => {
                              return <div>
                                <span>{item?.name}</span>
                              </div>
                            }
                            )}</div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                      <tr>
                        <td className="categoryList3">
                          <div className="location"><span>Location:</span>{item?.Address ? item?.Address : 'Not available'}</div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                      <tr>
                        <td className="categoryList3">
                          <div className="location"><span>Website:</span>{item?.websiteLink ? item?.websiteLink : 'Not available'}</div>
                          <div className="location"><span>twitter:</span>{item?.twitterLink ? item?.twitterLink : 'Not available'}</div>
                        </td>
                        <td className="categoryList3 locationTime">
                          <div className="location"><span>Phone number:</span>{item?.phoneNumber ? item?.phoneNumber : 'Not available'}</div>
                          <div className="location"><span>Facebook:</span>{item?.facebookLink ? item?.facebookLink : 'Not available'}</div>
                          <div className="location"><span>Linkedin:</span>{item?.linkedLink ? item?.linkedLink : 'Not available'}</div>
                        </td>
                        <td className="categoryList3">
                          <div className="location"><span>Email address:</span>{item?.email ? item?.email : 'Not available'}</div>
                          <div className="location"><span>Instagram:</span>{item?.instagramLink ? item?.instagramLink : 'Not available'}</div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        })}
      </div>
    </div>
  );

};

export default Event;
