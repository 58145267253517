import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button } from "react-bootstrap";
import './searchbar.scss'


const Searchbar = (props) => {
  const [toggleView, setToggleView] = useState(true);
  let { value, onSearch, onClear } = props
  return (
    <div><div className="headWrap">
      <div className="topbar-wrap">
        <div className="col-md-9 d-inline-block searchbox">
          <InputGroup className="mb-3">
            <InputGroup.Text id="searchInput">
              <span className="icon-search"></span>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="searchInput"
              onChange={props.onSearch}
              onClick={() => {
                onClear && onClear();
              }}
            />
          </InputGroup>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Searchbar