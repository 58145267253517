import React, { useState, useEffect } from "react";
import './CustomToggleButton.scss';

const CustomToggleButton = (props) => {
    let { onCheckChange } = props
    const [checked, setChecked] = useState(props.checked)
    let { id } = props

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    const onToggleButtonChecked = (e) => {
        return (
            onCheckChange && onCheckChange(e.target.checked)
        )
    }

    return (
        <label className="switch">
            <input type="checkbox" id={id} checked={checked} onChange={onToggleButtonChecked} />
            <span className="slider round"></span>
        </label>
    )
}

CustomToggleButton.defaultProps = {
    id: '',
    checked: false
}

export default CustomToggleButton;