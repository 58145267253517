
import './filter.scss'
import React, { useEffect, useState } from "react";
import logoImage from '../../assets/images/dundee-logo.svg'
import CheckBox from '../Checkbox/CheckBox'
import CategoryCheckbox from '../CategoryCheckbox/CategoryCheckbox'
import CustomToggleButton from '../CustomToggleButton/CustomToggleButton';
import { Button } from "react-bootstrap";


const Filter = (props) => {
  let tempCategory = props?.categoriesData;
  let filterServiceWrap = localStorage.getItem('serviceFilter');
  let filterEventWrap = localStorage.getItem('eventFilter');
  let filterToggleWrap = localStorage.getItem('toggleFilter');
  const [categories, setCategories] = useState([]);
  const [toggleFilter, setToggleFilter] = useState(true);
  const [service, setService] = useState(true);
  const [event, setEvent] = useState(true);
  let FromEvent = props?.isFromEvent;
  let FromBookmark = props?.isFromToggle;
  let tempCount = props?.count;
  let eventTempCount = props?.countedEvents;
  let bookmarked = props?.BookmarkFilter;


  return (
    <>

      {/* <div className={toggleFilter ? 'filterWrap' :'filterWrap active'}> */}
      <Button onClick={props.onclick}>
        <span className={props.iconChange}></span>
      </Button>
      <div className='innerWrap' >
        <div className='banner text-center'>
          <div className='logo'>
            <img src={logoImage} width='105' height='95' alt='logo' />
          </div>
        </div>
        <div className='filterTypeWrap'>
          <h1>FILTER <span>{eventTempCount ? eventTempCount : bookmarked ? bookmarked : tempCount}</span></h1>
          <div className='categories'>
            <h2>Categories</h2>
            <ul>
              {
                tempCategory?.map((item, index) => {
                  return (
                    < div key={index} className='checkboxRow' >
                      <CategoryCheckbox
                        id={`categories${item._id}`}
                        color={item.color}
                        // disabled={item.categoryName === 'Uncategorised'}
                        labelTitle={item.categoryName}
                        checked={item.status === "Enable" ? true : false}
                        onCheckedChange={(checked) => {
                          let newArr = tempCategory;
                          newArr[index].status = checked ? "Enable" : "Disable";
                          setCategories([...newArr])
                          props.onUpdatedCategoryData(newArr);
                          let filteredData = tempCategory?.filter((item) => {
                            return !(item?.status === 'Enable')
                          })
                          localStorage.setItem('homeFilter', JSON.stringify(filteredData));
                        }}

                      ></CategoryCheckbox>
                    </div>
                  )
                })
              }
            </ul>

          </div>
          {FromEvent !== true ?
            <div className='domains'>
              <h2>Domains</h2>
              <CheckBox
                labelTitle='Service'
                name='service'
                id='service'
                checked={filterServiceWrap ? filterServiceWrap === 'false' ? false : true : "true"}
                onCheckedChange={(serviceValue) => {
                  localStorage.setItem('serviceFilter', serviceValue);
                  props.onCheckedServices(serviceValue);
                  setService(serviceValue);


                }}
              />
              <CheckBox
                labelTitle='Event'
                name='event'
                id='event'
                checked={filterEventWrap ? filterEventWrap === 'false' ? false : true : 'true'}
                onCheckedChange={(checked) => {
                  if (checked === true) {
                    props.onUpdatedCategoryData([{ categoryName: "Uncategorised", color: "#000000", status: "Enable", _id: "6180f1fea7f078b966646017" }, ...tempCategory])
                  } else {
                    let updatedData = tempCategory?.filter((item) => {
                      return !(item.categoryName === 'Uncategorised')
                    })
                    props.onUpdatedCategoryData(updatedData)
                  }
                  localStorage.setItem('eventFilter', checked)
                  props.onCheckedEvent(checked);
                  setEvent(checked)
                }}
              />
            </div>
            :
            null
          }
          {FromBookmark !== true ?
            <div className='onGoing'>
              <p className='title'>Ongoing now</p>
              <CustomToggleButton
                onCheckChange={(checked) => {
                  props.onCheckedToggle(checked);
                  setToggleFilter(checked);
                  localStorage.setItem('toggleFilter', checked)
                }}
                checked={filterToggleWrap ? filterToggleWrap === 'false' ? false : true : false}
              />
            </div>
            : null
          }
        </div>
      </div >
    </>
  )
}

export default Filter