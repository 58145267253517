import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import moment from 'moment';
import MetaTags from 'react-meta-tags';

import AxiosInstance from "../../services/axios";
import Filter from '../../components/FIlter/Filter';
import ViewDetails from "../../components/ViewDetails/ViewDetails";
import Header from "../../layout/Header/Header";

import './bookmark.scss'

const Bookmark = () => {
  const [toggleFilter, setToggleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [viewPage, setViewPage] = useState({})
  const [bookmark, setBookmark] = useState([])
  const [categories, setCategories] = useState([]);

  const [checkToggle, setCheckToggle] = useState(true);
  const [service, setService] = useState(true);
  const [event, setEvent] = useState(true);
  const [bookMarkCount, setBookMarkCount] = useState();
  const bookMarkBookList = JSON.parse(localStorage.getItem('bookmark_data')) || [];
  let filterWrap = JSON.parse(localStorage.getItem('homeFilter'))


  useEffect(() => {
    let filteredBookmark = bookMarkBookList.filter((item) => {
      if (item.type == "event")
        return moment(item.eventEndDate).diff(moment(), 'minutes') > 0
      else
        return item
    });
    setBookmark(filteredBookmark);
    setBookMarkCount(filteredBookmark?.length)
  }, [showModal]);

  useEffect(() => {
    getFilterCategory();
  }, [])
  useEffect(() => {
    filterBookMarkData()
  }, [categories, service, event, checkToggle])


  const filterBookMarkData = async () => {
    let mainBookmark = JSON.parse(localStorage.getItem('bookmark_data'));
    let filteredBookmark = mainBookmark?.filter((item) => {
      if (item.type == "event")
        return moment(item.eventEndDate).diff(moment(), 'minutes') > 0
      else
        return item
    });

    const data = filteredBookmark?.filter((item, index) => {


      return categories?.some(function (o2) {
        if (event == true && service == true){
          return (o2.status == "Enable" && (item.categoryName === o2.categoryName))

        }
        if (event == true && service == false) {
          return (o2.status == "Enable" && item.type == "event" && (item.categoryName === o2.categoryName))

        } else if (event == false && service == true) {
          return (o2.status == "Enable" && item.type == "service" && (item.categoryName === o2.categoryName))
        } else {
          return (o2.status == "Enable" && (item.categoryName === o2.categoryName))

        }
        })
      });

    // const dateNow = moment().format('YYYY-MM-DD') + "T00:00:00.000Z";
    // const hourNow = moment().format('hh:mm');
    // const bookMarkBookList = data;
    // bookMarkBookList.filter((value, index, arr) => {
    //   if (bookMarkBookList[index].eventEndDate <= dateNow) {
    //     if(bookMarkBookList[index].eventEndTime < hourNow && bookMarkBookList[index].eventEndDate == dateNow){
    //       bookMarkBookList.splice(index, 1);
    //     } else if(bookMarkBookList[index].eventEndDate < dateNow){
    //       bookMarkBookList.splice(index, 1);
    //     }
    //   }
    // });
    // setBookmark(bookMarkBookList);
    // // localStorage.setItem('bookmark_data',JSON.stringify(bookmark));
    // setBookMarkCount(bookMarkBookList?.length)

    setBookmark(data)
    // localStorage.setItem('bookmark_data', JSON.stringify(data));
    setBookMarkCount(data?.length)
  }

  const getFilterCategory = async () => {
    try {
      await AxiosInstance.get('user/getCategory').then
        ((response) => {
          let temp = response?.payload;
          if (event == true) {
            let obj = {
              categoryName: "Uncategorised",
              color: "#000000",
              status: "Enable",
              _id: "6180f1fea7f078b966646017"
            }
            temp.unshift(obj)
          }


          const tempArray = temp.map((item, index) => {
            const filterData = filterWrap?.some((value) => value._id === item._id)
            if (filterData) {
              item['status'] = 'Disable';
            }
            return item;
          })

          setCategories(tempArray);
        })
    } catch (error) {

    }
  };



  const timingStatus = (dayTimeArray = [], status) => {
    const today = moment().utc();
    const day = today.format('ddd')
    let currentDay = {}
    let endTime;
    let startTime;
    let closeText = 'Closed';
    currentDay = dayTimeArray.find((d) => d.day === day);
    if (Object.keys(currentDay).length > 0) {
      startTime = moment(`${today.format('DD/MM/YYYY')} ${currentDay.startTime}`, 'DD/MM/YYYY HH:mm');
      endTime = moment(`${today.format('DD/MM/YYYY')} ${currentDay.endTime}`, 'DD/MM/YYYY HH:mm');
      if (today.isBetween(startTime, endTime) && status !== 'Close') {
        closeText = 'Open';
      }
    }
    return closeText;
  }

  return (
    <>
      <MetaTags>
        <title>Bookmarks | Dundee Recovery Road Map</title>
        <meta name="description" content="Save a list of upcoming events or local services to track sessions, see opening times, and have easier access to contact details." />
        <meta property="og:title" content="Bookmarks | Dundee Recovery Road Map" />
        <meta property="og:description" content="Save a list of upcoming events or local services to track sessions, see opening times, and have easier access to contact details." />
        <meta property="og:image" content="https://res.cloudinary.com/zudu/image/upload/v1667544192/Parish_Nursing/Parish-Nursing-Web/Project-images/socialmediapreview.png" />
      </MetaTags>
      <div className={toggleFilter ? "filterWrap" : "filterWrap active"}>
        <Filter
          BookmarkFilter={bookMarkCount}

          onclick={() => setToggleFilter(!toggleFilter)}
          iconChange={toggleFilter ? "icon-filter" : "icon-close"}
          categoriesData={categories}
          isFromToggle={true}
          onCheckedServices={(value) => {
            setService(value);
          }}
          onCheckedEvent={(value) => {
            setEvent(value);
          }}
          onUpdatedCategoryData={(value) => {
            setCategories([...value]);
          }}
        />
      </div>
      <div className={toggleFilter ? "mainWrap" : "mainWrap move"}>
        <Header />
        <ViewDetails
          mapData={viewPage}
          showModal={showModal}
          closeModal={() => setShowModal(false)}

        />
        <div className="bookmarkWrap listVieWrap">
          {(!bookmark ||bookmark?.length === 0) ?
            < div className="no_data">
              <i className='icon-info_circle_outline'></i>
              <p>Oops! No service centres or events bookmarked yet.</p>
            </div>
            :
            bookmark?.map((item, index) => {
              let displayTime;
              let startDate;
              let endDate;
              let st = 'Open';
              if (item.type === 'event') {
                startDate = moment(`${moment(item?.eventStartDate).utc().format("yyyy/MM/DD")}
                        ${item?.eventStartTime}`).format("DD MMM YYYY HH:mm");
                if (item?.eventEndDate) {
                  endDate = moment(`${moment(item?.eventEndDate).utc().format("yyyy/MM/DD")}
                            ${item?.eventEndTime}`).format("DD MMM YYYY HH:mm");
                } else {
                  endDate = "--:--"
                }
              } else {
                let currentDay = moment().format("ddd");
                let data = item?.availableDayTime?.filter((businessItem) => {
                  if (businessItem?.day === currentDay) {
                    return businessItem;
                  }
                  else if (businessItem?.day !== currentDay) {

                  }
                })
                st = timingStatus(item?.availableDayTime, item?.isActive);
                displayTime =
                  data[0]?.isDayActive ?
                    data[0].is24Hours ? "24 Hours available" :
                      `${data[0].startTime} to ${data[0].endTime}` : "Closed";
              }

              return (

                <div key={index} onClick={() => {
                  setShowModal(true)
                  setViewPage(item)
                  item["isbookmarked"] = true;
                }}
                  style={{ color: item.color }} className={"eventBox "}>
                  {item?.type === "event" ? (
                    <span className="icon icon-activity-pin"></span>
                  ) : (
                    <span className="icon icon-service-pin"></span>
                  )}
                  <div className="cardContent">
                    <div className="eventTop ">
                      <h2>
                        {item?.name}
                        {item?.distance &&
                          <span className="distance">{parseFloat(item?.distance).toFixed(2)} miles</span>
                        }
                      </h2>
                      <span className="category">{item?.categoryName}</span>
                    </div>
                    {item?.isActive !== "Open" && item?.isActive !== "open" ? (
                      <div className="timing">
                        <span>Timing - </span> {item?.isActive}
                      </div>
                    ) : (
                      <div className="timing">
                        {" "}
                        <span>{item.timing}</span>
                      </div>
                    )}
                    <div className="date">{item?.type === 'event' ? `${startDate} - ${endDate}` : displayTime}</div>
                    <div className="btn-wrap">
                      <a onClick={(e) => { e.stopPropagation(); }} className="btn btn-primary" href={`https://www.google.com/maps/?q=${item.location.coordinates[1]},${item.location.coordinates[0]}`}
                        target="_blank" rel="noreferrer" >
                        <span className="icon icon-coolicon"></span>Directions
                      </a>
                      <Button onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                        const getbookMarkBookArr = JSON.parse(localStorage.getItem('bookmark_data'));
                        const isBookMarkAdded = getbookMarkBookArr?.some((itemData) => item._id === itemData._id)
                        if (isBookMarkAdded) {
                          if (getbookMarkBookArr && getbookMarkBookArr.length > 0) {
                            const newData = getbookMarkBookArr.filter((val) => item._id !== val._id)
                            setBookmark(newData)
                            // props.closeModal();
                            localStorage.setItem('bookmark_data', JSON.stringify(newData))
                          }
                        }
                      }}>
                        <span className="icon icon-bin">
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  )
}
export default Bookmark