import HomeMapView from "./pages/HomeMapView/HomeMapView";
import Event from './pages/Event/Event'
import Bookmark from "./pages/Bookmark/Bookmark";

const routes = [
    {
        path : '/',
        component: <HomeMapView />
    },
    {
        path : '/homemapview',
        component: <HomeMapView />
    },
    {
        path : '/event',
        component: <Event />
    },
    {
        path : '/bookmark',
        component: <Bookmark />
    }
]
export default routes