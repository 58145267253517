import React, { useState, useEffect } from 'react';
import './CheckBox.scss';

const CheckBox = (props) => {
    const { labelTitle, id, onCheckedChange, register, name, disabled, uniqueId } = props;
    const [checked, setChecked] = useState(!props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const onChecked = (e) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };

    return (
        <div className="checkboxMain" key={`check_${uniqueId}`}>
            <input type="checkbox" ref={register} disabled={disabled} name={name} value={labelTitle} id={id} className='checkbox' checked={checked} onChange={onChecked} onClick={props.onClick}/>
            <label className='checkboxLabel' id="checkLabel" htmlFor={id}>{labelTitle}</label>
        </div>
    );
};

CheckBox.defaultProps = {
    id: '',
    checked: false,
    labelTitle: 'Title'
};

export default CheckBox;
