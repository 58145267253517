import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import "./viewdetails.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import useGeoLocation from "../../hooks/useGeoLocation";
import { isContentEditable } from "@testing-library/user-event/dist/utils";


const ViewDetails = (props) => {
  let showList = props?.mapData;
  const [bookmarkBtn, setBookmarkBtn] = useState(false);
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [serviceDetail, setServiceDetail] = useState({});




  useEffect(() => {
    setServiceDetail(showList);
  }, [showList]);

  const location = useGeoLocation();
  let serviceData = "";
  let endDate = "";
  let displayDate = "";

  let bookMarkArr = JSON.parse(localStorage.getItem("bookmark_data"));

  const serviceTimingsDetails = (data) => {
    const serviceData = data?.availableDayTime?.map((item) => {
      let currentDay = moment().format("ddd");
      let displayTime = "";
      if (item.day === currentDay) {
        displayTime = item.isDayActive
          ? item.is24Hours
            ? "24 Hours available" + `${data.day}`
            : item.startTime + " - " + item.endTime + " " + `${data.isActive}`
          : "Closed";
      } else {
        displayTime = item.isDayActive
          ? item.is24Hours
            ? "24 Hours available"
            : item.startTime + " - " + item.endTime
          : "Closed";
      }
      return getFullDay(item.day) + " " + displayTime;

    });
    return serviceData;
  };

  let StartDate = moment(`${moment(serviceDetail?.eventStartDate)
    .utc()
    .format("yyyy/MM/DD")}
    ${serviceDetail?.eventStartTime}`).format("DD MMM YYYY HH:mm");

  if (serviceDetail?.eventStartTime) {
    if (showList?.eventEndDate) {
      endDate = moment(`${moment(showList?.eventEndDate)
        .utc()
        .format("yyyy/MM/DD")}
            ${showList?.eventEndTime}`).format("DD MMM YYYY HH:mm");

      displayDate =
        moment(StartDate).format("DD MMM") + " " + "to" + " " +
        moment(endDate).format("DD MMM") + " " +
        moment(endDate).format("YYYY") + "," + " " +
        moment(StartDate).format("HH:mm") + "-" + " " +
        moment(endDate).format("HH:mm");
    } else {
      endDate = "--:--";
      displayDate =
        moment(StartDate).format("DD MMM") +
        "," +
        moment(StartDate).format("HH:mm");
    }
  } else {
    displayDate = serviceDetail?.eventEndTime;
  }

  const downloadPDF = (data) => {
    const input = document.getElementById("showViewDetails");
    html2canvas(input, { useCORS: true, pagesplit: true }).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 600;
      var pageHeight = 600;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "pt", "a4");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("file.pdf");
    });
  };
  const getFullDay = (day) => {
    switch (day) {
      case "Mon":
        return "Monday";

      case "Tue":
        return "Tuesday";

      case "Wed":
        return "Wednesday";

      case "Thu":
        return "Thursday";

      case "Fri":
        return "Friday";

      case "Sat":
        return "Saturday";

      case "Sun":
        return "Sunday";
    }
  };

  return (
    <>
      <Offcanvas
        placement="end"
        show={props.showModal}
        onHide={() => {
          props.closeModal();
        }}

      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="activityDetails" id="activityDetails">
            <div className="detialBox ">
              <div className="eventTop bottom-border orange">
                <div className="topLine">
                  {serviceDetail?.type === "event" ? (
                    <span className="icon icon-activity-pin" style={{ color: serviceDetail?.color }}></span>
                  ) : (
                    <span className="icon icon-service-pin" style={{ color: serviceDetail?.color }}></span>
                  )}
                  <span className="category" style={{ color: serviceDetail?.color }}>
                    {serviceDetail?.categoryName}
                  </span>
                  {serviceDetail?.distance &&
                    <span className="distance">
                      {parseFloat(serviceDetail?.distance).toFixed(2)} miles
                    </span>
                  }
                </div>
                <h2>{serviceDetail?.name}</h2>

                <div className="address">
                  <span className="icon icon-marker"></span>
                  {serviceDetail?.Address}
                </div>
                <div className="btn-wrap">
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="btn btn-primary"
                    href={`https://www.google.com/maps/?q=${serviceDetail?.location?.coordinates[1]},${serviceDetail?.location?.coordinates[0]}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="icon icon-coolicon"></span>Directions
                  </a>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setBookmarkBtn(true);
                      const getbookMarkBookArr = JSON.parse(
                        localStorage.getItem("bookmark_data")
                      );
                      const isBookMarkAdded = getbookMarkBookArr?.some(
                        (itemData) => serviceDetail?._id === itemData._id
                      );
                      let localList = [...filteredDataSource];

                      if (isBookMarkAdded) {
                        if (
                          getbookMarkBookArr &&
                          getbookMarkBookArr.length > 0
                        ) {
                          const newData = getbookMarkBookArr.filter(
                            (val) => serviceDetail._id !== val._id
                          );
                          let temp = serviceDetail;
                          temp["isbookmarked"] = false;
                          setServiceDetail(temp);
                          localStorage.setItem(
                            "bookmark_data",
                            JSON.stringify(newData)
                          );
                          props.closeModal()
                        }
                      } else {
                        if (bookMarkArr && bookMarkArr.length > 0) {
                          bookMarkArr.push(serviceDetail);
                          let temp = serviceDetail;
                          temp["isbookmarked"] = true;
                          setServiceDetail(temp);
                          localStorage.setItem("bookmark_data", JSON.stringify(bookMarkArr));
                        } else {
                          localStorage.setItem("bookmark_data",
                            JSON.stringify([serviceDetail])
                          );
                        }
                      }
                      setFilteredDataSource([...localList]);
                    }}
                    className={
                      serviceDetail?.isbookmarked === true ? "isbookmarked" : ""
                    }
                  >
                    <span className="icon icon-bookmark-outline"></span>
                    Bookmark
                  </Button>
                  <Button
                    onClick={() => {
                      downloadPDF();
                    }}
                  >
                    <span className="icon icon-download directions"></span>
                  </Button>
                </div>
                <div className="description">{serviceDetail?.description}</div>
                <div className="pins">
                  {serviceDetail?.type === "service" &&
                    serviceDetail?.relatedEvents.map((item, index) => {
                      return (
                        <div key={index} >
                          <span className="icon icon-activity-pin color-icon nameMap">
                            <p className="text_name">{item?.name}</p>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="time bottom-border">
                {serviceDetail?.type === "service" ? (
                  <Accordion flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {serviceDetail?.availableDayTime?.map((item, index) => {
                          let currentDay = moment().format("ddd");
                          if (item.day === currentDay) {
                            const timing = item.isDayActive ? item.is24Hours ? "24 Hours available" : item.startTime + "-" + item.endTime : "Closed";
                            return (
                              <>
                                <span key={index} className="icon icon-calendar"></span>
                                <div>
                                  <span>{item.day}</span>
                                  {timing}
                                  <span>{showList?.isActive === "Close" ? "Closed" : showList?.isActive}</span>
                                </div>
                              </>
                            );
                          }
                        })}
                      </Accordion.Header>
                      <Accordion.Body>
                        {serviceDetail?.availableDayTime?.map((item, index) => {
                          let currentDay = moment().format("ddd");
                          if (item.day !== currentDay) {
                            const timing = item.isDayActive
                              ? item.is24Hours
                                ? "24 Hours available"
                                : item.startTime + "-" + item.endTime
                              : "Closed";
                            return (
                              <>
                                <div className="timeWrap" key={index}>
                                  <span>{item.day}</span>
                                  <span>{timing}</span>
                                </div>
                              </>
                            );
                          }
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  <>
                    <span className="icon icon-calendar"></span>
                    <div>
                      <span>{displayDate}</span>
                      <span className="isActive">{serviceDetail?.isActive}</span>
                    </div>
                  </>
                )}
              </div>
              {serviceDetail?.phoneNumber ? (
                <div className="phone bottom-border">
                  <span className="icon icon-call"></span>
                  {serviceDetail?.phoneNumber}
                </div>
              ) : ''}
              {serviceDetail?.email ? (
                <div className="email bottom-border">
                  <span className="icon icon-mail"></span>
                  {serviceDetail?.email}
                </div>
              ) : null}
              {serviceDetail?.websiteLink ? (
                <div className="website bottom-border">
                  <span className="icon icon-link"></span>
                  {serviceDetail?.websiteLink}
                </div>
              ) : null}
              <div className="social bottom-border">
                {serviceDetail?.facebookLink ? (
                  <a href={serviceDetail?.facebookLink}>
                    <span className="icon icon-facebook"></span>
                  </a>
                ) : null}
                {serviceDetail?.twitterLink ? (
                  <a href={serviceDetail?.twitterLink}>
                    <span className="icon icon-twitter"></span>
                  </a>
                ) : null}
                {serviceDetail?.instagramLink ? (
                  <a href={serviceDetail?.instagramLink}>
                    <span className="icon icon-instagram"></span>
                  </a>
                ) : null}
                {serviceDetail?.linkedLink ? (
                  <a href={serviceDetail?.linkedLink}>
                    <span className="icon icon-linkedin"></span>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div id="showViewDetails" className="showViewData">
          <table className="showTable">
            <tbody>
              <tr>
                <td>
                  <table className="titleTable">
                    <thead>
                      <tr>
                        <td colSpan="2" className="service">
                          {serviceDetail?.type === "event" ? (
                            <span>Events</span>
                          ) : (
                            <span>Service Centres</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                    </thead>
                  </table>
                  <table className="secondTable">
                    <tbody>
                      <tr>
                        <td className="categoryList">
                          <div className="category">
                            {serviceDetail?.categoryName}
                          </div>
                          <div className="name">{serviceDetail?.name}</div>
                          <div className="description">
                            {serviceDetail?.description}
                          </div>
                        </td>
                        <td className="categoryList categoryList1">
                          <div className="serviceTime">
                            {serviceDetail?.type === "event" ? (
                              <span>Event Timing</span>
                            ) : (
                              <span>Service Timing</span>
                            )}
                          </div>
                          <div className={serviceDetail?.type === "service" ? "serviceData" : "eventDataStyle"}>
                            {serviceDetail?.type === "service" ? (
                              serviceDetail?.availableDayTime?.map(
                                (item, index) => {
                                  let currentDay = moment().format("ddd");
                                  if (item.day === currentDay) {
                                    const timing = item.isDayActive
                                      ? item.is24Hours
                                        ? "24 Hours available"
                                        : item.startTime + "-" + item.endTime
                                      : "Closed";
                                    return (
                                      <>
                                        <div className="serviceTiming" key={index}>
                                          <span className="serviceDay">
                                            {item.day}
                                          </span>
                                          {timing}
                                          <span className="isActive serviceDay">
                                            {showList?.isActive === 'Close' ? 'Closed' : item?.isActive}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  } else {
                                    const timing = item.isDayActive
                                      ? item.is24Hours
                                        ? "24 Hours available"
                                        : item.startTime + "-" + item.endTime
                                      : "Closed";
                                    return (
                                      <>
                                        <div className="serviceTiming">
                                          <span className="serviceDay">
                                            {item.day}
                                          </span>
                                          {timing}
                                        </div>
                                      </>
                                    );
                                  }
                                }
                              )
                            ) : (
                              <>
                                <span className="icon icon-calendar"></span>
                                <div>
                                  <span>{displayDate}</span>
                                  <span className="isActive">{serviceDetail?.isActive}</span>
                                </div>
                              </>
                            )}
                          </div>
                        </td>


                        <td className="categoryList categoryList2">
                          {serviceDetail?.relatedEvents?.length > 0 && <div className="eventTime">{serviceDetail?.type === 'service' ? 'Events' : ''}</div>}
                          <div className="eventData">
                            {serviceDetail?.type === "service" &&
                              serviceDetail?.relatedEvents?.map((item, index) => {
                                return (
                                  <div key={index}>
                                    <span className="mapName">{item?.name}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="categoryList3">
                          <div className="location">
                            <span>Location:</span>
                            {serviceDetail?.Address
                              ? serviceDetail?.Address
                              : "Not available"}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ height: 16 }} height={"16"}></td>
                      </tr>
                      <tr>
                        {serviceDetail?.type === "service" ? (
                          <>
                            <td className="categoryList3">
                              <div className="location">
                                <span>Website:</span>
                                {serviceDetail?.websiteLink
                                  ? serviceDetail?.websiteLink
                                  : "Not available"}
                              </div>
                              <div className="location">
                                <span>Twitter:</span>
                                {serviceDetail?.twitterLink
                                  ? serviceDetail?.twitterLink
                                  : "Not available"}
                              </div>
                            </td>
                            <td className="categoryList3 locationTime">
                              <div className="location">
                                <span>Phone number:</span>
                                {serviceDetail?.phoneNumber
                                  ? serviceDetail?.phoneNumber
                                  : "Not available"}
                              </div>
                              <div className="location">
                                <span>Facebook:</span>
                                {serviceDetail?.facebookLink
                                  ? serviceDetail?.facebookLink
                                  : "Not available"}
                              </div>
                              <div className="location">
                                <span>LinkedIn:</span>
                                {serviceDetail?.linkedLink
                                  ? serviceDetail?.linkedLink
                                  : "Not available"}
                              </div>
                            </td>
                            <td className="categoryList3">
                              <div className="location">
                                <span>Email address:</span>
                                {serviceDetail?.email
                                  ? serviceDetail?.email
                                  : "Not available"}
                              </div>
                              <div className="location">
                                <span>Instagram:</span>
                                {serviceDetail?.instagramLink
                                  ? serviceDetail?.instagramLink
                                  : "Not available"}
                              </div>
                            </td>
                          </>
                        ) : null}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Offcanvas>
    </>
  );
};

export default ViewDetails;
