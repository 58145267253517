import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import routes from "./routes";
import "./App.scss";
import useGeoLocation from "./hooks/useGeoLocation";

function App() {

  const location = useGeoLocation();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {routes.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path}
                element={item.component}
                exact={item.exact}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
