import { Container, Modal } from 'react-bootstrap';
import React from "react";
import './oursupporters.scss'


const OurSupporters = (props) => {

    return (
        <>
       
        <Modal centered className='supportersModal' show={props.showModal} onHide={()=>{
            props.modalClose()
        }}>
          <Modal.Header closeButton>
            <Modal.Title className='support-head'>Our Supporters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='d-flex flex-wrap text-center align-items-center justify-content-between'>
                <a href='http://www.svru.co.uk/' target='_blank'> <img src='https://res.cloudinary.com/zudu/image/upload/v1659335012/Parish_Nursing/Parish-Nursing-Web/Project-images/violence-reduction-unit.jpg' alt='Violance Reduction Unit' height='46' width='140' /></a>
                <a href='https://www.qnis.org.uk/' target='_blank'> <img src='https://res.cloudinary.com/zudu/image/upload/v1659335012/Parish_Nursing/Parish-Nursing-Web/Project-images/qnis.jpg' alt='The Queens Nurising Institute Scotland' height='91' width='150' /></a> 
                <a href='https://streetsoccerscotland.org/' target='_blank'> <img src='https://res.cloudinary.com/zudu/image/upload/v1659335012/Parish_Nursing/Parish-Nursing-Web/Project-images/street-soccer-scotland.jpg' alt='Street Soccer Scotland' height='91' width='194' /></a> 

               </div>
              <div className='d-flex flex-wrap text-center align-items-center justify-content-between'>

                <a href='https://dundeeandangus.foodbank.org.uk/' target='_blank'> <img src='https://res.cloudinary.com/zudu/image/upload/v1667466702/Parish_Nursing/Parish-Nursing-Web/Project-images/foodbank.webp' alt='Dundee And Angus foodbank' height='91' width='150' /></a> 
                <a href='https://thesteeplechurch.org.uk/parish-nursing/' target='_blank'>   <img src='https://res.cloudinary.com/zudu/image/upload/v1667466703/Parish_Nursing/Parish-Nursing-Web/Project-images/processed-49f46b7d-d2a4-40db-b47a-966c541fb083_8SClZeWt.jpg' alt='Parish Nurising Dundee' height='95' width='105' /></a> 
                <a href=' https://dvva.scot/' target='_blank'>  <img src='https://res.cloudinary.com/zudu/image/upload/v1659335012/Parish_Nursing/Parish-Nursing-Web/Project-images/dundee-volunteer.jpg' alt='Dundee Volunteer and Volantary Action' height='93' width='234' /></a> 
              </div>

              <div className='d-flex flex-wrap text-center align-items-center justify-content-start'>

                <img src='https://res.cloudinary.com/zudu/image/upload/v1667477775/Parish_Nursing/Parish-Nursing-Web/Project-images/Kolorkey_Digital_Logo-tagline.png' alt='Kolorkey Digital' height='150' width='185' />
</div>
            </Container>
          </Modal.Body>
        </Modal>
        </>
    );
};
export default OurSupporters;